<template>
  <v-card>
    <v-form ref="form" @submit.prevent="handleChangeNameAsset">
      <v-card-title class="text-h5" primary-title>
        <h6 class="text-h6">
          {{
            $trans("change_name_asset_title", {
              file: getConfirmDialog.data.name,
            })
          }}
        </h6>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="4">
              <v-img :src="getUrlPicture" aspect-ratio="1" />
            </v-col>
            <v-col cols="8">
              <v-text-field
                v-model="form.newName"
                outlined
                validate-on-blur
                color="secondary"
                :label="$trans('change_name_asset_new_name')"
                :rules="[rules.required, rules.maxChars(30)]"
                :disabled="isLoading"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="light" text :disabled="isLoading" @click="$emit('close')">
          {{ $trans("cancel") }}
        </v-btn>
        <v-btn color="primary" text type="submit" :disabled="isLoading">
          {{ $trans("save") }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";
import { maxChars, required } from "../../../forms/validators";
import api from "@/lib/calendesk-js-library/api/api";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "ChangeNameAsset",
  mixins: [sharedActions],
  data() {
    return {
      isLoading: false,
      rules: {
        required,
        maxChars,
      },
      form: {
        newName: "",
      },
    };
  },
  computed: {
    getUrlPicture() {
      return `${this.$config("image_base_url")}${
        this.getConfirmDialog.data.default_image.name
      }`;
    },
  },
  mounted() {
    this.form.newName = this.getConfirmDialog.data.name;
  },
  methods: {
    ...mapMutations({
      setReload: "assets/SET_RELOAD",
    }),
    handleChangeNameAsset() {
      if (!this.$refs.form.validate()) {
        return;
      }

      pushEvent("changeImageAssetName");

      this.isLoading = true;
      this.$emit("loading", this.isLoading);
      api
        .updateUserImage(this.getConfirmDialog.data.id, {
          name: this.form.newName,
        })
        .then((response) => this.setReload())
        .catch((error) => this.$log.error(error))
        .finally(() => {
          this.isLoading = false;
          this.$emit("loading", this.isLoading);
          this.$emit("close");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-image {
  border-radius: 6px;
}
</style>
